import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "@material-ui/core/Grid"
// import Box from '@material-ui/core/Box';
import Hidden from "@material-ui/core/Hidden"
import SliderTeam from "../components/SliderTeam"
// import Helmet from "react-helmet"

const Location = () => {
  return (
    <React.Fragment>
      <Grid item className="beach"></Grid>
      <Grid item className="ourstory_location_copy">
        <h1>Location is key</h1>
        <p>
          Brown & Kay 20 Seamoor Road, Westbourne BH4 9AR is just a few doors
          along from the very popular ‘Chez Fred’ Fish & Chip restaurant and
          also the Main Post Office.  Opposite is the beautiful Arcade offering
          local independent gift shops, card shops, boutiques and jewellers ..
          all under cover!  Westbourne retains a village feel with a great range
          of local bars but also main stream business like Marks & Spencer Food
          Hall and a Tesco Express. {" "}
        </p>
      </Grid>
    </React.Fragment>
  )
}

const OurStory = () => (
  <Layout>
    <SEO title="Our Story" />

    <Grid container className="ourstory_holder">
      {/* header */}
      <Grid container className="ourstory1">
        <Grid item className="ourstory1a">
          100 years combined experience.
        </Grid>
      </Grid>

      <Grid container className="ourstory2a">
        <Grid item>
          <div className="ourstory_circle"></div>
        </Grid>
        <Grid item className="ourstory_circle_header ourstory_content">
          2016
        </Grid>

        <Grid item className="ourstory2a_contentholder">
          <Grid item className="ourstory_content1">
            <h1>From strength to strength</h1>
            <p>
              Brown & Kay Estate & Letting agents was established in 2006 in
              Poole Town Centre. After many successful years a second flagship
              branch was opened in the bustling location of Westbourne.{" "}
            </p>
            <p>
              Since then Brown & Kay have continued to offer the customer care
              and service levels that we know our clients deserve. Check our 5*
              reviews on Facebook, excellent Google reviews and now you can
              follow us on instagram!
            </p>{" "}
          </Grid>

          <Grid item className="teampic"></Grid>
        </Grid>

        <Grid container className="ourstory_experience">
          <Grid item>
            <div className="ourstory_circle"></div>
          </Grid>
          <Grid item className="ourstory_circle_header ourstory_content">
            OUR EXPERIENCE
          </Grid>
        </Grid>

        <Grid container className="ourstory_location1">
          <h1>Location is key</h1>
        </Grid>
      </Grid>
      {/* our story2a */}

      <Hidden only={["xs", "sm"]}>
        <Grid item className="ourstory_location2">
          <Location />
        </Grid>
      </Hidden>

      <Hidden only={["md", "lg", "xl"]}>
        <Grid container className="ourstory_location2">
          <Location />
        </Grid>
      </Hidden>

      <Grid container className="ourstory2b">
        <Grid item>
          <div className="ourstory_circle"></div>
        </Grid>

        <Grid item className="ourstory_circle_header ourstory_content">
          KNOWLEDGE
        </Grid>

        <Grid container className="ourstory_knowledge_holder">
          <Grid item className="ourstory_knowledge_copy">
            There is still a butcher, an artisan baker and a fruit and veg shop.
            Charity shops mingle with high end interior design business and Art
            galleries offering a truly eclectic shopping and leisure experience.
            With pathways down ‘The Chines’ to the award winning beaches this is
            truly a wonderful place to live. Bus services run to Bournemouth and
            beyond and the main Railway station in Bournemouth offers regular
            travel to London Waterloo and the rest of the country. A 10 minute
            walk from Westbourne takes you to local Rail Station Branksome for a
            quick trip to Poole, Weymouth or Dorchester. The M27 is a short
            drive away and links to the M3.
          </Grid>

          <Grid item>
            <div className="shop"></div>
          </Grid>
        </Grid>

        <Grid item>
          <div className="ourstory_circle_end"></div>
        </Grid>
      </Grid>
    </Grid>
    {/* our story holder */}
    <Grid container className="hp4">
      <Grid container className="grid1_hp4">
        <h1>Introducing our team</h1>
      </Grid>

      <SliderTeam />
    </Grid>
  </Layout>
)

export default OurStory
