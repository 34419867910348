import React, { useRef, useEffect, useCallback, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import gql from "graphql-tag"
import { Query } from "react-apollo"
import DOMPurify from "dompurify"
import useDynamicRefs from "use-dynamic-refs"

const APOLLO_QUERY_TEAM = gql`
  {
    tEAMMEMBERS {
      edges {
        node {
          TEAM_MEMBER {
            email
            position
            image {
              mediaItemUrl
            }
          }
          databaseId
          title
          content
        }
      }
    }
  }
`

const scrollSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  initialSlide: 1,
  centerMode: true,
  variableWidth: false,
  // centerPadding:'-170px',
  centerPadding: "140px",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        centerMode: true,
        centerPadding: 140,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        centerMode: true,
        centerPadding: 50,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        centerMode: true,
        centerPadding: 210,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: false,
        centerMode: false,
        centerPadding: 30,
      },
    },
    // {
    //   breakpoint: 480,
    //   settings: {
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     dots:false
    //   }
    // }
  ],
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className="arrowNext"
      // style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className="arrowPrev"
      // style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  )
}

const SliderTeam = () => {
  const [getRefTeam, setRefTeam] = useDynamicRefs()
  const [memberValue, setMemberValue] = useState("")
  const sanitizer = DOMPurify.sanitize

  const updateTeamMember = i => {
    setMemberValue(i)
  }

  const emailStaffMember = email => {
    document.location.href = `mailto:${email}?subject=Email from Brown and Kay`
  }

  return (
    <Query query={APOLLO_QUERY_TEAM}>
      {({ data }) => {
        if (data) {
          let contentImg = data?.tEAMMEMBERS?.edges?.map((item, index) => {
            return (
              <div key={index} className="cardTeam">
                <div className="cardTeam_image_holder">
                  {item?.node?.TEAM_MEMBER?.image?.mediaItemUrl && (
                    <img
                      key={index}
                      className="img"
                      src={item?.node?.TEAM_MEMBER?.image?.mediaItemUrl}
                    />
                  )}
                </div>
                <div
                  style={{ opacity: memberValue === index ? 1 : 0 }}
                  onClick={() => updateTeamMember(index)}
                  ref={setRefTeam("team_" + index)}
                >
                  <div
                    className="cardTeam_info_big"
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(item?.node?.content),
                    }}
                  ></div>
                </div>
                <div className="cardTeam_info_small">
                  <div className="cardTeam_info_small_section">
                    <div className="cardTeam_info_name">
                      {item?.node?.title}
                    </div>
                    <div className="cardTeam_info_title">
                      {item?.node?.TEAM_MEMBER?.position}
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      emailStaffMember(item?.node?.TEAM_MEMBER?.email)
                    }
                    className="cardTeam_info_small_section email_box"
                  >
                    <div>Email</div>
                    <div>&gt;</div>
                  </div>
                </div>
              </div>
            )
          })

          return (
            <div className="containerTeam">
              <Slider {...scrollSettings}>{contentImg}</Slider>
            </div>
          )
        }

        return null
      }}
    </Query>
  )
}

export default SliderTeam
